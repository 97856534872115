import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

import './badge.js';
import './header.js';
import './profile.js';
import './overview.js';
import './domain-details.js';

export class IntrivityPrintable extends LitElement {

    static get properties() {
        return {
            user_profile: { type: Object },
            space_domain: { type: Object },
            message_domain: { type: Object },
            relationship_domain: { type: Object },
            time_domain: { type: Object },            
            report: { type: Object }
        };
    }

    constructor() {
        super();
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    render() {
        return html`
                <intrivity-header printable="true" .user_profile=${this.user_profile}></intrivity-header>
                <div>     
                    <intrivity-overview></intrivity-overview>
                    <intrivity-profile .report=${this.report} .message_domain=${message_domain} .space_domain=${space_domain} .relationship_domain=${relationship_domain} .time_domain=${time_domain}></intrivity-profile>   
                    <intrivity-domain-details domain_prefix="m" .domain=${message_domain} .report=${this.report}></intrivity-domain-details>
                    <intrivity-domain-details domain_prefix="s" .domain=${space_domain} .report=${this.report}></intrivity-domain-details>                    
                    <intrivity-domain-details domain_prefix="t" .domain=${time_domain} .report=${this.report}></intrivity-domain-details>  
                    <intrivity-domain-details domain_prefix="r" .domain=${relationship_domain} .report=${this.report}></intrivity-domain-details>  
                </div>
        `
    } 
 
}
if (!customElements.get('intrivity-printable')) customElements.define('intrivity-printable', IntrivityPrintable);
