import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './badge.js';

import { db, emitEvent } from './mrts.js';
import { INTRIVITY_LOGOUT } from './nav.js';
import { doc, setDoc, getDoc, collection, addDoc, getFirestore } from "firebase/firestore";


export class UserProfileFieldEditor extends LitElement {

    static get styles() {
        return css`
            .header {
                width: 100%;
                align-self: flex-end;
                display: grid;
                grid-template-columns: 1fr 5fr 1fr 1fr;
            }
            .user_edit {
                cursor: pointer;
                color: #424141;
                white-space: nowrap; 
                margin-left: 10px; 
                font-size: 0.8vmax;
            }
        `;
    }
    
    static get properties() {
        return {
            show_value: { type: Boolean },
            user_profile: { type: Object },
            field: { type: String },
            value: { type: String },
            editing: { type: Boolean }
        };
    }

    constructor() {
        super();
        this.saveData = this.saveData.bind(this);
        this.editing = false;
        //this.show_value = true;
    }

    async saveData(e) {
            let payload = cloneDeep(this.user_profile);
            payload[this.field] = this.value;
            try {
                await setDoc(doc(db, "users", this.user_profile.email), payload, { merge: true });
                this.user_profile[this.field]=this.value;
                this.editing = false; 
                this.requestUpdate();
            } catch (x) {
                console.log(x);
                alert("There was a problem saving your profile data.");
            }        
    }
    
    render() {
        return html`
            ${this.editing ? html`
                <mwc-textfield style="width: 70%;" @change=${(e)=>{ 
                     this.value = e.target.value;
                    }} label="${this.field}" value=${this.value ? this.value: ''}></mwc-textfield>
                    <span @click=${this.saveData} class="user_edit">save</span> 
                    <span @click=${(e)=>{ this.value = this.user_profile[this.field] ? this.user_profile[this.field]: ''; this.editing = false;}} class="user_edit">cancel</span>
            `: html`
                ${this.show_value ? html`${this.user_profile[this.field]}`: html``} 
                 <span @click=${(e)=>{ this.editing = true;}} class="user_edit">edit ${this.field}</span>
            `}
        `
    } 
 
}
if (!customElements.get('user-profile-field-editor')) customElements.define('user-profile-field-editor', UserProfileFieldEditor);
