import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import { emitEvent, hasLocalStorage } from './mrts.js';
import { DOMAIN_EDITOR_RELEASE } from './domain_editor.js';
import { INTRIVITY_LOAD_RESOURCES } from './nav.js';

import './domain_editor.js';

// Manage Domains
export class IntrivityAdminDomains extends LitElement {

    static get styles() {
        return css`

        `;
    }
    
    static get properties() {
        return {
            user_profile: { type: Object },
            selected: { type: String }       
        };
    }

    constructor() {
        super();
        this.released = this.released.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener(DOMAIN_EDITOR_RELEASE, this.released);   
    }
    
    disconnectedCallback() {
        document.removeEventListener(DOMAIN_EDITOR_RELEASE, this.released);
        super.disconnectedCallback();
    }
    
    released(e) {
        this.selected = undefined;
        this.requestUpdate();
        //localStorage.getItem("username") === null
    }
    
    render() {
        return html`
            ${this.selected ? html`
                <intrivity-domain-editor .user_profile=${this.user_profile} .domain_name=${this.selected}></intrivity-domain-editor>
            `: html`
                <div style="margin: 10px;">
                    <h3>Edit domain feedback:</h3>
                    <mwc-button outlined @click=${(e)=> { this.selected = 'message'; this.requestUpdate(); } }>
                        ${hasLocalStorage('domains/message_editor') ? html`Resume Editing Message`: html`Check out Message`}
                    </mwc-button>
                    <mwc-button outlined @click=${(e)=> { this.selected = 'relationship'; this.requestUpdate(); } }>
                        ${hasLocalStorage('domains/relationship_editor') ? html`Resume Editing Relationship`: html`Check out Relationship`}
                    </mwc-button>
                    <mwc-button outlined @click=${(e)=> { this.selected = 'time'; this.requestUpdate(); } }>
                        ${hasLocalStorage('domains/time_editor') ? html`Resume Editing Time`: html`Check out Time`}
                    </mwc-button>
                    <mwc-button outlined @click=${(e)=> { this.selected = 'space'; this.requestUpdate(); } }>
                        ${hasLocalStorage('domains/space_editor') ? html`Resume Editing Space`: html`Check out Space`}
                    </mwc-button>
                    <mwc-button style="margin-top: 5px;" outlined @click=${(e)=> { emitEvent(INTRIVITY_LOAD_RESOURCES, {}, this); } }>
                        Reload Domain Configurations Locally
                    </mwc-button>
                </div>
            `}
            
        `
    } 
 
}
if (!customElements.get('intrivity-admin-domains')) customElements.define('intrivity-admin-domains', IntrivityAdminDomains);
