import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import { emitEvent } from './mrts.js';
import { INTRIVITY_LOAD_CLIENTS } from './nav.js';


// Manage Clients
export class IntrivityAdminClients extends LitElement {

    static get styles() {
        return css`

        `;
    }
    
    static get properties() {
        return {
            clients: { type: Array },
            expanded: { type: Boolean }   
        };
    }

    constructor() {
        super();
        emitEvent( INTRIVITY_LOAD_CLIENTS, {}, this );
        console.log("emitted load client event");
        this.expanded = false;
    }

    connectedCallback() {
        super.connectedCallback(); 
    }
    
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    render() {
        return html`
            
            <div style="margin-bottom: 5px; width: 100%; color: black; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: gray;">
                CLIENTS  
                ${this.expanded ? html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,10 20,10 10,0" /></svg>
                `: html`
                    <svg @click=${(e)=>{this.expanded = !this.expanded;}} height="1em" viewBox="0 0 20 10"><polygon fill="black" stroke-width=0 points="0,0 20,0 10,10" /></svg> 
                `}
            </div>            
            
            ${this.expanded ? html`
 
                ${this.clients.map((client) => html`
                    ${client.address}
                    ${client.department}
                    ${client.email}
                    ${client.institution}
                    ${client.name}
                    ${client.primary_user}
                `)}
        
            `: html`

            `}
            
        `
    } 
 
}
if (!customElements.get('intrivity-admin-clients')) customElements.define('intrivity-admin-clients', IntrivityAdminClients);
