import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import { emitEvent, functions } from './mrts.js';

import { getFunctions, httpsCallable } from "firebase/functions";


// Manage Domains
export class IntrivityAdminSurveys extends LitElement {


    static get styles() {
        return css`

        `;
    }
    
    static get properties() {
        return {
            user_profile: { type: Object },
            selected: { type: String },
            vendor_surveys: { type: Array },
            vendor_survey: { type: Object },
            vendor_selected_id: { type: String }
        };
    }

    getSurveys() {
        
    }
    
    getSurveyListFromSurveyMonkey() {
        const SMSurveys = httpsCallable(functions, 'SMSurveys');
        SMSurveys({})
            .then((result) => {
              this.vendor_surveys = result.data;
            }).catch((error) => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log (error);   
            });
    }
    
    getSurveyFromSurveyMonkey() {
        const SMSurveys = httpsCallable(functions, 'SMSurvey');
        SMSurvey({ 'id': this.vendor_selected_id })
            .then((result) => {
              this.vendor_survey = result.data;
            }).catch((error) => {
                // Getting the Error details.
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.log (error);   
            });   
    }
    
    constructor() {
        super();
        this.getSurveyListFromSurveyMonkey = this.getSurveyListFromSurveyMonkey.bind(this);
        this.getSurveyFromSurveyMonkey = this.getSurveyFromSurveyMonkey.bind(this);
    }

    connectedCallback() {
        super.connectedCallback();
        //document.addEventListener(DOMAIN_EDITOR_RELEASE, this.released); 
        this.getSurveyListFromSurveyMonkey();
    }
    
    disconnectedCallback() {
        //document.removeEventListener(DOMAIN_EDITOR_RELEASE, this.released);
        super.disconnectedCallback();
    }
    
    
    render() {
        return html`
                <div style="margin: 10px;">
                    <h3>Manage surveys:</h3>
                </div>
        `
    } 
 
}
if (!customElements.get('intrivity-admin-surveys')) customElements.define('intrivity-admin-surveys', IntrivityAdminSurveys);
