import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import { getStorage, ref, getDownloadURL, uploadBytes, deleteObject } from "firebase/storage";

import { emitEvent, hasRole, storage } from './mrts.js';

import '@material/mwc-textfield';

export const DOMAIN_EDITOR_CANCEL = "Intrivity::DomainEditor::Cancel";
export const DOMAIN_EDITOR_SAVE = "Intrivity::DomainEditor::Save";
export const DOMAIN_EDITOR_PUBLISH = "Intrivity::DomainEditor::Publish";
export const DOMAIN_EDITOR_RELEASE = "Intrivity::DomainEditor::Release";

export class IntrivityDomainEditor extends LitElement {

    // check if local editing version... 
    // if not.. check if stored editing version...
    // if not load from current version
    getJSONFromStorage(file_name) {
        try {
            let val = localStorage.getItem(file_name);
            if (val) {
                this.original = JSON.parse(val);
                this.domain = cloneDeep(this.original);
            } else {
                this.loadJSONFromStorage(file_name);
            }
        } catch (e) {
            console.log(e);
        }
    }    
    loadJSONFromStorage(file_name) {
        const storageRef = ref(storage, file_name);
        getDownloadURL(storageRef)
            .then(url => fetch(url))
            .then(response => response.json())
            .then(data => { 
                if (file_name === 'domains/' + this.domain_name + '_editor') {
                    // check if WE are the editor
                    if (data.editor && data.editor === this.user_profile.email) {
                        localStorage.setItem(file_name, JSON.stringify(data));
                        this.original = data;
                        this.domain = cloneDeep(this.original);
                        this.editor = true;
                    } else {
                        this.editor = false;
                        this.original = data;
                    }
                } else {
                    data.editor = this.user_profile.email;
                    data.checked_out = new Date().toISOString();
                    this.putJSONInStorage('domains/' + this.domain_name + '_editor', data);
                }
                this.requestUpdate(); 
            }).catch(reason => {
                console.log(reason);
                if (file_name === 'domains/' + this.domain_name + '_editor') {
                    console.log('try loading:' + 'domains/domain_' + this.domain_name + '.json');
                    this.loadJSONFromStorage('domains/domain_' + this.domain_name + '.json');
                } else {
                    this.editor = false;
                    alert("Could not load data.");
                }
            });
    }
    putJSONInStorage = async function(name, json, save, publish) {
        var data = JSON.stringify(json);
        const blob = new Blob([data], { type: "application/json" });

        const storageRef = ref(storage, name);
        uploadBytes(storageRef, blob).then((snapshot) => {
            if (!publish) localStorage.setItem(name, data);         
            this.original = json;
            this.domain = cloneDeep(this.original);
            this.editor = true;  
            emitEvent(DOMAIN_EDITOR_SAVE, {}, this);
            this.requestUpdate();
            if (save) { alert("Your changes have been saved."); }
            if (publish) { 
                alert("Your changes have been published.");
                this.editor = false;
                this.deleteFromStorage('domains/' + this.domain_name + '_editor', true);
            }
        }).catch(reason => {
            console.log(reason);
            if (save) { 
                alert("There was a problem saving your changes."); }         
            else if (publish) { 
                alert("There was a problem publishing your changes."); 
            } else {
                this.editor = false;
            }
        }); 
        
    }
    deleteFromStorage = async function(name, release) {
        const storageRef = ref(storage, name);
        deleteObject(storageRef).then(() => {
            if (release) {
                localStorage.removeItem(name);
                this.editor = false;
                this.domain = undefined;
                emitEvent(DOMAIN_EDITOR_RELEASE, {}, this);
                this.requestUpdate();
                alert("File released.");
            }
        }).catch((error) => {
            console.log(error);
            alert("Deletion failed for " + name + ".");
        });  

    }
    
    hasChanges() {
        return !(JSON.stringify(this.domain) === JSON.stringify(this.original));
    }
    
    connectedCallback() {
        super.connectedCallback();
    }
    
    firstUpdated() {
        this.getJSONFromStorage('domains/' + this.domain_name + '_editor');
    }
    
    cancelChanges(e) {
        if (this.hasChanges()) {
            this.domain = cloneDeep(this.original);
            emitEvent(DOMAIN_EDITOR_CANCEL, {}, this);
            this.requestUpdate();
            alert("Changes have been reverted.");
        } else {
            alert("No changes to cancel.");
        }        
    }
    
    saveChanges(e) {
        if (this.hasChanges()) {
            if (!this.domain.saved) {
                this.domain.saved = [];
            }
            let editlog = {};
            editlog.editor = this.domain.editor;
            editlog.checked_out = this.domain.checked_out;
            editlog.saved = new Date();
            this.domain.saved.push(editlog);
            localStorage.setItem('domains/' + this.domain_name + '_editor', JSON.stringify(this.domain));
            this.putJSONInStorage('domains/' + this.domain_name + '_editor', this.domain, true);
            emitEvent(DOMAIN_EDITOR_SAVE, {}, this);
            this.requestUpdate();
        } else {
            alert("No changes to save.");
        }        
    }
    
    releaseChanges(e) {
        if (confirm("Are you sure you want to release your checkout?  Any unpublished changes will be lost.")) {
            this.deleteFromStorage('domains/' + this.domain_name + '_editor', true);
        }         
    }
    
    publishChanges(e) {
        if (!this.hasChanges()) {
            if (confirm("Are you sure you certain you want to publish your changes?  This will update the production data and release the checkout.")) {
                let payload = cloneDeep(this.domain);
                if (!payload.published) {
                    payload.published = [];
                }
                let editlog = {};
                editlog.editor = payload.editor;
                editlog.checked_out = payload.checked_out;
                editlog.published = new Date();
                payload.published.push(editlog);
                delete payload.editor;
                delete payload.checked_out;
                delete payload.saved;
                this.putJSONInStorage('domains/domain_' + this.domain_name + '.json', payload, false, true);
            }
        } else {
            alert("You must save your changes before you can publish them.");
        }        
    }
    
    
    constructor() {
        super(); 
        this.loadJSONFromStorage = this.loadJSONFromStorage.bind(this);
        this.putJSONInStorage = this.putJSONInStorage.bind(this);
        this.deleteFromStorage = this.deleteFromStorage.bind(this);
        this.hasChanges = this.hasChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.releaseChanges = this.releaseChanges.bind(this);
        this.publishChanges = this.publishChanges.bind(this);
    }    
    
    static get properties() {
        return {
            user_profile: { type: Object },
            domain_name: { type: String },
            domain: { type: Object },
            original: { type: Object },
            editor: { type: Boolean }
        };
    }

    static get styles() {
        return css`
            .field_name {
                font-size: small;
                text-transform: uppercase;
                font-weight: bolder;
                margin-bottom: 10px;
            }
            .field_value {
                margin-bottom: 20px;
            }
            li {
                margin-bottom: 15px;
            }
        `;
    }
    
    render() {
        return html`
            <div style="margin: 20px;">
            ${this.user_profile && this.original ? html`
                ${this.domain ? html`
                
                    <div style="background-color: lightgrey; padding: 20px;">
                        This editor will allow you to change any text directly that
                        is colored blue.  You may use HTML code if you like but the
                        HTML will not be rendered (you won't see it change on this 
                        screen and you will see the HTML code).  Only one user can
                        edit one domain at a time- it will be locked to other users
                        until the edit is saved or cancelled.  Saving changes will
                        immediately publish those changes and subsequent loads of
                        the application will see these changes. The save and cancel
                        options appear at the bottom of this page.
                    </div>
        
                    <div style="margin-top: 10px; background-color: #FF9999; padding: 20px;">
                        <span style="font-weight: bold;">Warning:</span> if you leave this page- for example by 
                        choosing a menu item on the left- the editor will act
                        as if you clicked the Cancel button and any changes you
                        have made since your last Save will be lost.
                    </div>        
                    
                    <h2>${this.domain.name} Domain  <span style="font-size: small;">Editing by ${this.domain.editor} checked out at ${new Date(this.domain.checked_out)}.</span></h2>
        
                    <div style="background-color: lightgrey; padding: 20px;">
        
                        <div class="field_name">Description</div>
                        <div class="field_value"> 
                           <intrivity-domain-editor-editable @change=${(e) => { this.domain.description = e.detail.value; }} .content=${this.domain.description}></intrivity-domain-editor-editable>
                        </div>

                        <div class="field_name">Domain Level Feedback</div>
                        <div class="field_value">
                            <ul>
                                <li>   
                                    <intrivity-domain-editor-editable @change=${(e) => { this.domain.feedback.a = e.detail.value; console.log(this.domain); }} .content=${this.domain.feedback.a}></intrivity-domain-editor-editable>
                                </li>
                                <li>   
                                    <intrivity-domain-editor-editable @change=${(e) => { this.domain.feedback.b = e.detail.value; console.log(this.domain); }} .content=${this.domain.feedback.b}></intrivity-domain-editor-editable>
                                </li>
                                <li>   
                                    <intrivity-domain-editor-editable @change=${(e) => { this.domain.feedback.c = e.detail.value; console.log(this.domain); }} .content=${this.domain.feedback.c}></intrivity-domain-editor-editable>
                                </li>
                                <li>   
                                    <intrivity-domain-editor-editable @change=${(e) => { this.domain.feedback.d = e.detail.value; console.log(this.domain); }} .content=${this.domain.feedback.d}></intrivity-domain-editor-editable>
                                </li>
                                <li>   
                                    <intrivity-domain-editor-editable @change=${(e) => { this.domain.feedback.e = e.detail.value; console.log(this.domain); }} .content=${this.domain.feedback.e}></intrivity-domain-editor-editable>
                                </li>        
                            </ul>
                        </div>
        
                    </div>
        
                    <h3>${this.domain.name} Domain Characteristics</h3>
        
                    ${this.domain.level_two.map((item) => html`
                        <div style="background-color: lightgrey; padding: 20px; margin-bottom: 20px;">
        
                            <div class="field_name">Characteristic Name</div>
                            <div class="field_value"> 
                               <intrivity-domain-editor-editable @change=${(e) => { item.name = e.detail.value; }} .content=${item.name}></intrivity-domain-editor-editable>
                            </div>        

                            <div class="field_name">Description</div>
                            <div class="field_value"> 
                               <intrivity-domain-editor-editable @change=${(e) => { item.description = e.detail.value; }} .content=${item.description}></intrivity-domain-editor-editable>
                            </div> 
        
                            <div class="field_name">Likert Scale Labels</div>
                            <div class="field_value"> 
                               Left: <intrivity-domain-editor-editable @change=${(e) => { item.left_label = e.detail.value; }} .content=${item.left_label}></intrivity-domain-editor-editable>
                            </div> 
                            <div class="field_value"> 
                               Right: <intrivity-domain-editor-editable @change=${(e) => { item.right_label = e.detail.value; }} .content=${item.right_label}></intrivity-domain-editor-editable>
                            </div>        
        
                            <div class="field_name">Characteristic Feedback</div>
                            <div class="field_value">
                                <ul>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { item.feedback.a = e.detail.value; console.log(this.domain); }} .content=${item.feedback.a}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { item.feedback.b = e.detail.value; console.log(this.domain); }} .content=${item.feedback.b}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { item.feedback.c = e.detail.value; console.log(this.domain); }} .content=${item.feedback.c}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { item.feedback.d = e.detail.value; console.log(this.domain); }} .content=${item.feedback.d}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { item.feedback.e = e.detail.value; console.log(this.domain); }} .content=${item.feedback.e}></intrivity-domain-editor-editable>
                                    </li>        
                                </ul>
                            </div>
                            
                            <div class="field_name">Characteristic Behaviors</div>
                            ${item.behaviors.map((behavior) => html`
                            
                                <div style="background-color: Gainsboro; padding: 20px; margin-bottom: 20px;">
        
                                    <div class="field_name">Behavior Name</div>
                                    <div class="field_value"> 
                                       <intrivity-domain-editor-editable @change=${(e) => { behavior.name = e.detail.value; }} .content=${behavior.name}></intrivity-domain-editor-editable>
                                    </div> 
        
                                    <div class="field_name">Behavior Quote</div>
                                    <div class="field_value"> 
                                       <intrivity-domain-editor-editable @change=${(e) => { behavior.quote = e.detail.value; }} .content=${behavior.quote}></intrivity-domain-editor-editable>
                                    </div>        

                                    <div class="field_name">Behavior Definition</div>
                                    <div class="field_value"> 
                                       <intrivity-domain-editor-editable @change=${(e) => { behavior.definition = e.detail.value; }} .content=${behavior.definition}></intrivity-domain-editor-editable>
                                    </div>        

                                    <div class="field_name">Likert Scale Labels</div>
                                    <div class="field_value"> 
                                       Left: <intrivity-domain-editor-editable @change=${(e) => { behavior.left_label = e.detail.value; }} .content=${behavior.left_label}></intrivity-domain-editor-editable>
                                    </div> 
                                    <div class="field_value"> 
                                       Right: <intrivity-domain-editor-editable @change=${(e) => { behavior.right_label = e.detail.value; }} .content=${behavior.right_label}></intrivity-domain-editor-editable>
                                    </div>        

                                    <div class="field_name">Behavior Sample</div>      
                                    <div class="field_value"> 
                                       <intrivity-domain-editor-editable @change=${(e) => { behavior.sample = e.detail.value; }} .content=${behavior.sample}></intrivity-domain-editor-editable>
                                    </div>

                                    <div class="field_name">Behavior Meaning</div> 
                                    <div class="field_value">
                                        <ul>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.meaning.a = e.detail.value; }} .content=${behavior.meaning.a}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.meaning.b = e.detail.value; }} .content=${behavior.meaning.b}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.meaning.c = e.detail.value; }} .content=${behavior.meaning.c}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.meaning.d = e.detail.value; }} .content=${behavior.meaning.d}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.meaning.e = e.detail.value; }} .content=${behavior.meaning.e}></intrivity-domain-editor-editable>
                                            </li>         
                                        </ul>
                                    </div>

                                    <div class="field_name">Behavior Strengths</div> 
                                    <div class="field_value">
                                        <ul>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.strengths.a = e.detail.value; }} .content=${behavior.strengths.a}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.strengths.b = e.detail.value; }} .content=${behavior.strengths.b}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.strengths.c = e.detail.value; }} .content=${behavior.strengths.c}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.strengths.d = e.detail.value; }} .content=${behavior.strengths.d}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.strengths.e = e.detail.value; }} .content=${behavior.strengths.e}></intrivity-domain-editor-editable>
                                            </li>         
                                        </ul>   
                                    </div>

                                    <div class="field_name">Behavior Recommendations</div> 
                                    <div class="field_value">
                                        <ul>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.recommendations.a = e.detail.value; }} .content=${behavior.recommendations.a}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.recommendations.b = e.detail.value; }} .content=${behavior.recommendations.b}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.recommendations.c = e.detail.value; }} .content=${behavior.recommendations.c}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.recommendations.d = e.detail.value; }} .content=${behavior.recommendations.d}></intrivity-domain-editor-editable>
                                            </li>
                                            <li>   
                                                <intrivity-domain-editor-editable @change=${(e) => { behavior.recommendations.e = e.detail.value; }} .content=${behavior.recommendations.e}></intrivity-domain-editor-editable>
                                            </li>        
                                        </ul>  
                                    </div>
                                </div>
                            `)}
                            
                        </div>
                    `)}
        
                    <h3>${this.domain.name} Domain Attitudes</h3>
        
                    ${this.domain.attitudes.map((attitude) => html`
                            
                        <div style="background-color: Gainsboro; padding: 20px; margin-bottom: 20px;">

                            <div class="field_name">Attitude Name</div>
                            <div class="field_value"> 
                               <intrivity-domain-editor-editable @change=${(e) => { attitude.name = e.detail.value; }} .content=${attitude.name}></intrivity-domain-editor-editable>
                            </div> 

                            <div class="field_name">Attitude Quote</div>
                            <div class="field_value"> 
                               <intrivity-domain-editor-editable @change=${(e) => { attitude.quote = e.detail.value; }} .content=${attitude.quote}></intrivity-domain-editor-editable>
                            </div>        

                            <div class="field_name">Attitude Definition</div>
                            <div class="field_value"> 
                               <intrivity-domain-editor-editable @change=${(e) => { attitude.definition = e.detail.value; }} .content=${attitude.definition}></intrivity-domain-editor-editable>
                            </div>        

                            <div class="field_name">Likert Scale Labels</div>
                            <div class="field_value"> 
                               Left: <intrivity-domain-editor-editable @change=${(e) => { attitude.left_label = e.detail.value; }} .content=${attitude.left_label}></intrivity-domain-editor-editable>
                            </div> 
                            <div class="field_value"> 
                               Right: <intrivity-domain-editor-editable @change=${(e) => { attitude.right_label = e.detail.value; }} .content=${attitude.right_label}></intrivity-domain-editor-editable>
                            </div>        
  
                            <div class="field_name">Attitude Sample</div>      
                            <div class="field_value"> 
                               <intrivity-domain-editor-editable @change=${(e) => { attitude.sample = e.detail.value; }} .content=${attitude.sample}></intrivity-domain-editor-editable>
                            </div>

                            <div class="field_name">Attitude Meaning</div> 
                            <div class="field_value">
                                <ul>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.meaning.a = e.detail.value; }} .content=${attitude.meaning.a}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.meaning.b = e.detail.value; }} .content=${attitude.meaning.b}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.meaning.c = e.detail.value; }} .content=${attitude.meaning.c}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.meaning.d = e.detail.value; }} .content=${attitude.meaning.d}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.meaning.e = e.detail.value; }} .content=${attitude.meaning.e}></intrivity-domain-editor-editable>
                                    </li>         
                                </ul>
                            </div>

                            <div class="field_name">Attitude Strengths</div> 
                            <div class="field_value">
                                <ul>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.strengths.a = e.detail.value; }} .content=${attitude.strengths.a}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.strengths.b = e.detail.value; }} .content=${attitude.strengths.b}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.strengths.c = e.detail.value; }} .content=${attitude.strengths.c}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.strengths.d = e.detail.value; }} .content=${attitude.strengths.d}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.strengths.e = e.detail.value; }} .content=${attitude.strengths.e}></intrivity-domain-editor-editable>
                                    </li>         
                                </ul>   
                            </div>

                            <div class="field_name">Attitude Recommendations</div> 
                            <div class="field_value">
                                <ul>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.recommendations.a = e.detail.value; }} .content=${attitude.recommendations.a}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.recommendations.b = e.detail.value; }} .content=${attitude.recommendations.b}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.recommendations.c = e.detail.value; }} .content=${attitude.recommendations.c}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.recommendations.d = e.detail.value; }} .content=${attitude.recommendations.d}></intrivity-domain-editor-editable>
                                    </li>
                                    <li>   
                                        <intrivity-domain-editor-editable @change=${(e) => { attitude.recommendations.e = e.detail.value; }} .content=${attitude.recommendations.e}></intrivity-domain-editor-editable>
                                    </li>        
                                </ul>  
                            </div>
                        </div>
                    `)}
        
                    <mwc-button outlined @click=${this.saveChanges}>Save</mwc-button>
                    <mwc-button outlined @click=${this.cancelChanges}>Cancel</mwc-button>
                    <mwc-button outlined @click=${this.publishChanges}>Publish</mwc-button>
                    <mwc-button outlined @click=${this.releaseChanges}>Release</mwc-button>
        
                    ${this.domain.published ? html`
                        <div style="margin: 10px; font-size: smaller;">
                            History:
                            ${this.domain.published.map((history) => html`
                                <div sytle="font-size: smaller;">${new Date(history.published).toString()} by ${history.editor}</div>
                            `)}
                        </div>
                    `: html``}
                    
                `: html`
                    <h2>This domain is checked out by ${this.original.editor}.</h2>
                    <div>Checked out at ${new Date(this.original.checked_out)}</div>
                `}
            `: html`
                ${this.editor ? html`
                    ${this.original ? html`
                        <h2>${this.domain.name} Domain  <span style="font-size: small;">Editing by ${this.domain.editor} checked out at ${new Date(this.domain.checked_out)}.</span></h2>
                    `: html`
                        <h2>Could not load...</h2>
                    `}         
                `: html`
                    <h2>Loading...</h2>
                `}
                
            `}
            </div>
        `
    } 
 
}
if (!customElements.get('intrivity-domain-editor')) customElements.define('intrivity-domain-editor', IntrivityDomainEditor);
export class IntrivityDomainEditorEditable extends LitElement {
    
    constructor() {
        super();  
        this.cancelled = this.cancelled.bind(this);
        this.saved = this.saved.bind(this);
    }   
    
    static get properties() {
        return {
            content: { type: String, state: true },
            buffer: { type: String, state: true }
        };
    }

    connectedCallback() {
        super.connectedCallback();
        document.addEventListener(DOMAIN_EDITOR_CANCEL, this.cancelled);
        document.addEventListener(DOMAIN_EDITOR_SAVE, this.saved);
        document.addEventListener(DOMAIN_EDITOR_SAVE, this.saved);
        this.buffer = this.content;
    }
    
    disconnectedCallback() {
        document.removeEventListener(DOMAIN_EDITOR_CANCEL, this.cancelled);
        document.removeEventListener(DOMAIN_EDITOR_SAVE, this.saved);
        super.disconnectedCallback()
    }
    
    //firstUpdated() {
    //    this.buffer = this.content;
    //}

    static get styles() {
        return css`
            [contentEditable] {
                color: blue;
            }   
        `;
    }
    
    cancelled(e) {
        this.shadowRoot.getElementById('target').textContent = this.content;
        this.requestUpdate();
    }
    
    saved(e) {
        this.requestUpdate();
    }    
    
    render() {
        return html`
            <span id="target" @blur=${(e) => {
            emitEvent('change', { value: this.buffer }, this);
        }} @input=${(e)=> {
            this.buffer = this.shadowRoot.getElementById('target').textContent;
            this.requestUpdate();
        }} contentEditable="true">${this.content ? this.content: '[No content]'}</span>`;
    } 
 
}
if (!customElements.get('intrivity-domain-editor-editable')) customElements.define('intrivity-domain-editor-editable', IntrivityDomainEditorEditable);

