export const report = {
    "m": {
        "average": 4.5,
        "level_two": [
            {
                "average": 4.5,
                "behaviors": [
                    { "average": 4.2 },
                    { "average": 4.8 },
                    { "average": 4.6 }
                ]
            },
            {
                "average": 4.9,
                "behaviors": [
                    { "average": 5 },
                    { "average": 4.8 }                    
                ]
            },
            {
                "average": 4.0,
                "behaviors": [
                    { "average": 3.8 },
                    { "average": 4.2 }                    
                ]
            }               
        ]
    },
    "s": {
        "average": 1.7,
        "level_two": [
            {
                "average": 1.6,
                "behaviors": [
                    { "average": 1.4 },
                    { "average": 1.8 }
                ]
            },
            {
                "average": 2.2,
                "behaviors": [
                    { "average": 2.1 },
                    { "average": 2.2 }                    
                ]
            },
            {
                "average": 1.3,
                "behaviors": [
                    { "average": 1.5 },
                    { "average": 1 }                    
                ]
            }                 
        ]
    },
    "r": {
        "average": 2.4,
        "level_two": [
            {
                "average": 2.5,
                "behaviors": [
                    { "average": 2 },
                    { "average": 3 }
                ]
            },
            {
                "average": 2.4,
                "behaviors": [
                    { "average": 2.8 },
                    { "average": 2 }                    
                ]
            },
            {
                "average": 2.3,
                "behaviors": [
                    { "average": 2.3 },
                    { "average": 2.3 }                    
                ]
            }                 
        ]
    },
    "t": {
        "average": 3.1,
        "level_two": [
            {
                "average": 3.3,
                "behaviors": [
                    { "average": 3.3 },
                    { "average": 3.3 }
                ]
            },
            {
                "average": 2.9,
                "behaviors": [
                    { "average": 2.3 },
                    { "average": 3.5 }                    
                ]
            },
            {
                "average": 3.0,
                "behaviors": [
                    { "average": 2.8 },
                    { "average": 3.2 }                    
                ]
            }                 
        ]
    }
}