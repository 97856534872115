export const report_2 = {
    "m": {
        "average": 2,
        "level_two": [
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 },
                    { "average": 2 }
                ]
            },
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }                    
                ]
            },
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }                    
                ]
            }               
        ]
    },
    "s": {
        "average": 2,
        "level_two": [
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }
                ]
            },
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }                    
                ]
            },
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }                    
                ]
            }                 
        ]
    },
    "r": {
        "average": 2,
        "level_two": [
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }
                ]
            },
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }                    
                ]
            },
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }                    
                ]
            }                 
        ]
    },
    "t": {
        "average": 2,
        "level_two": [
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }
                ]
            },
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }                    
                ]
            },
            {
                "average": 2,
                "behaviors": [
                    { "average": 2 },
                    { "average": 2 }                    
                ]
            }                 
        ]
    }
}