import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, getAdditionalUserInfo, signInWithPopup, signOut, connectAuthEmulator, 
           EmailAuthProvider, GoogleAuthProvider } from "firebase/auth"; 
import { doc, setDoc, getDoc, getDocs, collection, query, where, addDoc, getFirestore } from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { getFunctions } from 'firebase/functions';

import '@material/mwc-button';
import '@material/mwc-formfield';
import '@material/mwc-checkbox';
import '@material/mwc-textfield';
import '@material/mwc-textarea';
import '@material/mwc-select';
import '@material/mwc-list/mwc-list-item';

import { INTRIVITY_LOAD_RESOURCES, INTRIVITY_LOAD_CLIENTS, INTRIVITY_LOAD_GROUPS, INTRIVITY_GROUP_REPORTS_LOADED, INTRIVITY_GOOGLE_LOGGED_IN, INTRIVITY_GOOGLE_LOGGED_OUT, INTRIVITY_LOGIN, INTRIVITY_LOGGED_IN, INTRIVITY_LOGOUT, INTRIVITY_NAV_SELECT, INTRIVITY_PRINTABLE_TOGGLE, INTRIVITY_GROUP_SELECT } from './nav.js';
import './badge.js';
import './header.js';
import './nav.js';
import './name_banner.js';
import './profile.js';
import './user.js';
import './teams.js';
import './overview.js';
import './printable.js';
import './admin_config.js';
import './admin_clients.js';
import './admin_users.js';

import { report } from './resources/report.js';
import { report_1 } from './resources/report_1.js';
import { report_2 } from './resources/report_2.js';
import { report_3 } from './resources/report_3.js';
import { report_4 } from './resources/report_4.js';
import { report_5 } from './resources/report_5.js';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBPf-WUuhm7v-ixrQpuCuqg6xOG18ixLkk",
  authDomain: "my.intrivity.com",
  projectId: "intrivity-12c92",
  storageBucket: "intrivity-12c92.appspot.com",
  messagingSenderId: "931317535189",
  appId: "1:931317535189:web:8dfa9bb85f34f2828a6d5c",
  measurementId: "G-BEK1VQRZX6"
};
const app = initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
export const storage = getStorage();
export const functions = getFunctions(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const feedback_mapping = ['a','b','c','d','e','f','g'];
    
export let user;
export let defaultGroup;

export const emitEvent = function (eventName, eventDetails, element) {
    let event = new CustomEvent(
            eventName, {'bubbles': true, 'composed': true, 'detail': eventDetails});
    element.dispatchEvent(event);
};

export const hasRole = function(role, user) {
    return user && user.role && user.role.includes(role);
}

export const hasLocalStorage = function(name) {
    try {
        let val = localStorage.getItem(name);
        if (val) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.log(e);
        return false;
    }
}   

onAuthStateChanged(auth, (guser) => {
    if (guser) {          
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        console.log("AuthStageChange: Google Signed In Emit Event");
        console.log(guser);
        user = guser;
        emitEvent(INTRIVITY_GOOGLE_LOGGED_IN,{},document);
    } else {
        // User is signed out
        user = undefined;
        emitEvent(INTRIVITY_GOOGLE_LOGGED_OUT,{},document);
        console.log("AuthStageChange: Google Signed Out Emit Event");
    }
});

export const backgroundRow = function(i) {
        return i % 2 == 0 ? "background-color: #eee;" : "background-color: white;";
};

export const getScore = function(raw) {
        if (raw < 1.9) {
            return 1;
        } else if (raw < 2.7) {
            return 2;
        } else if (raw < 3.5) {
            return 3;
        } else if (raw < 4.3) {
            return 4;
        } else {
            return 5;
        }        
}

export const getColor = function(item, raw, color) {
        return item == getScore(raw) ? color: 'lightgrey';
}
    
export const getIcon = function(item, raw, color, size) {
        if (item == 1) {
            return html`
                <svg style="aspect-ratio: 1; width: ${size ? size: '25'}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${getColor(1, raw, color)}" fill="transparent" stroke-width="3" />
                </svg>`;
        } else if (item == 2) {
            return html`
                <svg style="aspect-ratio: 1; width: ${size ? size: '25'}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${getColor(2, raw, color)}" fill="transparent" stroke-width="1" />
                    <polygon points="${size ? size/2: 25/2},${size ? size: '25'} ${size ? size: '25'},${size ? size/2: 25/2} ${size ? size: '25'},${size ? size: '25'}" fill="${getColor(2, raw, color)}"/>
                </svg>
            `;
        } else if (item == 3) {
            return html`
                <svg style="aspect-ratio: 1; width: ${size ? size: '25'}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                  <rect width="100%" height="100%" stroke="${getColor(3, raw, color)}" fill="transparent" stroke-width="1" />
                  <polygon points="${size ? size: '25'},0 ${size ? size: '25'},${size ? size: '25'} 0,${size ? size: '25'}" fill="${getColor(3, raw, color)}"/>
                </svg>
            `;
        } else if (item == 4) {
            return html`
                <svg style="aspect-ratio: 1; width: ${size ? size: '25'}px;" xmlns="http://www.w3.org/2000/svg" version="1.1">
                    <rect width="100%" height="100%" stroke="${getColor(4, raw, color)}" fill="transparent" stroke-width="1" />
                    <polygon points="0,${size ? size/2: 12} 0,${size ? size: '25'} ${size ? size: '25'},${size ? size: '25'} ${size ? size: '25'},0 ${size ? size/2: 12},0" fill="${getColor(4, raw, color)}"/>
                </svg>    
            `;
        } else if (item == 5) {
            return html`
                <div style="align: center; background-color: ${getColor(5, raw, color)}; aspect-ratio: 1; width: ${size ? size: '25'}px;"></div>              
            `;
        }
}

export const putJSONInStorage = async function(name, json) {
    var data = JSON.stringify(json);
    const blob = new Blob([data], { type: "application/json" });
    
    const storageRef = ref(storage, name);
    uploadBytes(storageRef, blob).then((snapshot) => {
        console.log('Put in cloud storage ' + JSON.stringify(json));
    });
         
}

export class IntrivityMrts extends LitElement {

    static get properties() {
        return {
            user_profile: { type: Object },         // current user data
            masqueraded_user: { type: String },     // the user currently masqueraded
            current_selection: { type: String },
            selection: { type: String },
            base: {type: String},
            report: { type: Object },
            group_report: { type: Object },
            group_reports: { type: Array },
            printable: { type: Boolean },
            sample: { type: String },
            group_code: { type: String },
            space_domain: { type: Object },
            message_domain: { type: Object },
            relationship_domain: { type: Object },
            time_domain: { type: Object },
            post: { type: String },
            clients: { type: Array }
        };
    }

    constructor() {
        super();
        this.selection = "overview";
        this.base = "";
        this.post = "no";
        this.role = "Student";
        this.handleSelection = this.handleSelection.bind(this);
        this.getUserProfile = this.getUserProfile.bind(this);
        this.togglePrintable = this.togglePrintable.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.loggedout = this.loggedout.bind(this);
        this.getJSONFromStorage = this.getJSONFromStorage.bind(this);
        this.loadJSONFromStorage = this.loadJSONFromStorage.bind(this);
//        this.getGroupReports = this.getGroupReports.bind(this);
        this.loadGroupFromStorage = this.loadGroupFromStorage.bind(this);
        this.loadGroups = this.loadGroups.bind(this);
        this.loadClients = this.loadClients.bind(this);
        this.loadResources = this.loadResources.bind(this);
        this.groupSelected = this.groupSelected.bind(this);
        this.groupsLoaded = this.groupsLoaded.bind(this);
        this.printable = false;         
    }

    getJSONFromStorage(var_name, file_name) {
        try {
            let val = localStorage.getItem(var_name);
            if (val) {
                console.log('From local storage: ' + var_name);
                
                this[var_name] = JSON.parse(val);
                this.requestUpdate(); 
                
                // check if last update version is > 1 day old
                if (this[var_name].stored) {
                    let now = new Date();
                    let stored = new Date(this[var_name].stored);
                    let dayAgo = new Date(now.setDate(now.getDate() - 1));
                    if (dayAgo > stored) { 
                        console.log('Stored more than 1 day ago: ' + var_name);
                        this.loadJSONFromStorage(var_name, file_name); 
                    }
                } else {
                    console.log('No stored value for: ' + var_name);
                    this.loadJSONFromStorage(var_name, file_name);
                }
                
            } else {
                this.loadJSONFromStorage(var_name, file_name);
            }
        } catch (e) {
            console.log(e);
        }
    }
    
    loadJSONFromStorage(var_name, file_name) {
        console.log('Loading from cloud storage: ' + var_name);
        const storageRef = ref(storage, file_name);
        getDownloadURL(storageRef)
            .then(url => fetch(url))
            .then(response => response.json())
            .then(data => { 
                this[var_name] = data; 
                let store_this = cloneDeep(data);
                store_this.stored = new Date().toISOString();
                localStorage.setItem(var_name, JSON.stringify(store_this));
                this.requestUpdate(); 
            });
    }
    
    // Forcibly load resources from cloud
    async loadResources() {
        console.log("Reloading resources");
        this.loadJSONFromStorage("message_domain", "domains/domain_message.json");
        this.loadJSONFromStorage("space_domain", "domains/domain_space.json");
        this.loadJSONFromStorage("time_domain", "domains/domain_time.json");
        this.loadJSONFromStorage("relationship_domain", "domains/domain_relationship.json");
        this.requestUpdate();
    }

    firstUpdated() {
        this.loadGroups();
        //emitEvent( INTRIVITY_LOAD_GROUPS, {}, this );
    }
    
    connectedCallback() {
        super.connectedCallback();
        document.addEventListener(INTRIVITY_GOOGLE_LOGGED_IN, this.getUserProfile);
        document.addEventListener(INTRIVITY_GOOGLE_LOGGED_OUT, this.loggedout);     
        document.addEventListener(INTRIVITY_LOGIN, this.login);
        document.addEventListener(INTRIVITY_LOGGED_IN, this.getUserProfile);
        document.addEventListener(INTRIVITY_LOGOUT, this.logout);
        document.addEventListener(INTRIVITY_NAV_SELECT, this.handleSelection);
        document.addEventListener(INTRIVITY_PRINTABLE_TOGGLE, this.togglePrintable);
        document.addEventListener(INTRIVITY_LOAD_GROUPS, this.loadGroups);
        document.addEventListener(INTRIVITY_LOAD_CLIENTS, this.loadClients);
        document.addEventListener(INTRIVITY_LOAD_RESOURCES, this.loadResources);
        document.addEventListener(INTRIVITY_GROUP_SELECT, this.groupSelected);
        document.addEventListener(INTRIVITY_GROUP_REPORTS_LOADED, this.groupsLoaded);
        window.addEventListener("resize", (e)=> { this.requestUpdate(); }); 
        
        this.getJSONFromStorage("message_domain", "domains/domain_message.json");
        this.getJSONFromStorage("space_domain", "domains/domain_space.json");
        this.getJSONFromStorage("time_domain", "domains/domain_time.json");
        this.getJSONFromStorage("relationship_domain", "domains/domain_relationship.json");
        
        if (this.post == "yes") {
            if (user) this.getJSONFromStorage("report", "reports/" +  user.email + ".json");
        } else {
            if (this.sample === "1") {
                this.report = report_1;
            } else if (this.sample === "2") {
                this.report = report_2;
            } else if (this.sample === "3") {
                this.report = report_3;
            } else if (this.sample === "4") {
                this.report = report_4;
            } else if (this.sample === "5") {
                this.report = report_5;
            } else {
                this.report = report;
            }
        }
        this.requestUpdate();
        
        let group_hash = window.location.hash;
        if (group_hash && group_hash.length > 0) { 
            this.group_code = group_hash.substring(1);
            console.log(this.group_code);
        }
        
    }

    disconnectedCallback() {
        document.removeEventListener(INTRIVITY_GOOGLE_LOGGED_IN, this.getUserProfile);
        document.removeEventListener(INTRIVITY_GOOGLE_LOGGED_OUT, this.loggedout);
        document.removeEventListener(INTRIVITY_LOGIN, this.login);
        document.removeEventListener(INTRIVITY_LOGGED_IN, this.getUserProfile);
        document.removeEventListener(INTRIVITY_LOGOUT, this.logout);        
        document.removeEventListener(INTRIVITY_NAV_SELECT, this.handleSelection);
        document.removeEventListener(INTRIVITY_PRINTABLE_TOGGLE, this.togglePrintable);
        document.removeEventListener(INTRIVITY_LOAD_GROUPS, this.loadGroups);
        document.removeEventListener(INTRIVITY_LOAD_CLIENTS, this.loadClients);
        document.removeEventListener(INTRIVITY_GROUP_SELECT, this.groupSelected);
        document.removeEventListener(INTRIVITY_GROUP_REPORTS_LOADED, this.groupsLoaded);
        super.disconnectedCallback();
    }

    handleSelection(e) {
        if (e.detail.selection !== this.selection) {
            this.selection = e.detail.selection;
            window.scrollTo(0, 0);
            this.requestUpdate();
        }
    }
    
    groupSelected(e) {
        this.group_report = e.detail;
        this.requestUpdate();
    }
    
    logout() {
        signOut(auth).then(() => {
            this.loggedout();
        }).catch((error) => {
        }); 
    }
    
    loggedout() {
        user = undefined;
        this.user_profile = undefined;
        this.report = undefined;
        this.group_report = undefined;
        this.group_reports = undefined;
        localStorage.removeItem("report");
        localStorage.removeItem("group_report");
        this.selection = "overview";
        this.requestUpdate();        
    }
    
    masquerade(email) {
        this.masqueraded_user = email;
        this.user_profile = undefined;
        
    }
    
    quit_masquerade() {
        this.masqueraded_user = undefined;
    }
    
    groupsLoaded(e) {
        console.log(e);
        this.requestUpdate();
    }
    
    async login() {
        signInWithPopup(auth, provider)
            .then((result) => {
              const credential = GoogleAuthProvider.credentialFromResult(result);
              user = result.user;
              user.credential = credential;
              user.token = credential.accessToken;
              user.additional = getAdditionalUserInfo(result);
              this.getUserProfile();
            }).catch((error) => {
              // Handle Errors here.
              const errorCode = error.code;
              const errorMessage = error.message;
              const email = error.customData.email;
              //const credential = GoogleAuthProvider.credentialFromError(error);
              console.log("User failed to login.");
        });        
    }    
    
    async getUserGroup(gr) {
        //const docRef = doc(db, "groups", gr);
        //const docSnap = await getDoc(docRef);

        //if (docSnap.exists()) {
        //    defaultGroup = docSnap.data();
            this.getJSONFromStorage("group_report", "reports/groups/" + gr + ".json");  
        //} 
        this.requestUpdate();       
    }
    
    async loadClients() {
        console.log("loading clients");
        const snapshot = await getDocs(collection(db, "clients"));
        this.clients = snapshot.docs.map(doc => doc.data());
        console.log(this.clients);
        this.requestUpdate();
    }    
    
    
    async loadGroups() {
        console.log("LOAD GROUPS");
        let groups = [];
        if (this.user_profile && this.user_profile.groups) {
            
            for (let i=0; i < this.user_profile.groups.length; i++) {
console.log("updating load groups");
                try {
                    let val = localStorage.getItem("reports_" + this.user_profile.groups[i]);
                    if (val) {
                        
                        let group = JSON.parse(val);
                        
                        // check if last update version is > 1 day old
                        if (group.stored) {
                            let now = new Date();
                            let stored = new Date(group.stored);
                            let dayAgo = new Date(now.setDate(now.getDate() - 1));
                            if (dayAgo > stored) 
                                await groups.push(this.loadGroupFromStorage(groups, this.user_profile.groups[i], group));
                            else 
                                groups.push(group);
                        } else {
                            await groups.push(this.loadGroupFromStorage(groups, this.user_profile.groups[i] ));
                        }

                    } else {
                        await groups.push(this.loadGroupFromStorage(groups, this.user_profile.groups[i] ));
                    }
                } catch (e) {
                    console.log(e);
                }                
                
            }
        } else {
            console.log("did not load groups");
        }
        if (this.user_profile.group)
                    this.getUserGroup(this.user_profile.group);
        //this.group_reports = undefined;
        this.group_reports = groups;
        this.requestUpdate();
        emitEvent(INTRIVITY_GROUP_REPORTS_LOADED,{},document);
        
    }
    async loadGroupFromStorage(groups, group, failover) {
        try {
            const storageRef = ref(storage, "reports/groups/" + group + ".json");
            getDownloadURL(storageRef)
                .then(url => fetch(url))
                .then(response => response.json())
                .then(data => { 
                    groups.push(data); 
                    let store_this = cloneDeep(data);
                    store_this.stored = new Date().toISOString();
                    localStorage.setItem("reports_" + group, JSON.stringify(store_this));
                });
        } catch (x) {
            console.log(x);
            if (failover) groups.push(failover);
            return group;
        }
    }
    
    async getUserProfile() {
        if (user) {
            console.log("Getting the user's profile: " + (this.masqueraded_user ? this.masqueraded_user: user.email));
            const docRef = doc(db, "users", this.masqueraded_user ? this.masqueraded_user: user.email);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                this.user_profile = docSnap.data();
                console.log("Found user's profile: ");
                console.log(this.user_profile);
                if (this.user_profile.group)
                    this.getUserGroup(this.user_profile.group);
            } else {
                
                if (this.masqueraded_user) {
                    console.log("Masqueraded user not found!");
                    this.masqueraded_user = undefined;
                    return;
                }
                
                this.user_profile = {};
                // required fields..
                this.user_profile.email = user.email;
                this.user_profile.displayName = user.displayName;
                if (user.firstName) {
                    this.user_profile.firstName = user.firstName; 
                } else {
                    if (user.additional && user.additional.profile && user.additional.profile.given_name) 
                        this.user_profile.firstName = user.additional.profile.given_name;
                }
                if (user.lastName) {
                    this.user_profile.lastName = user.lastName; 
                } else {
                    if (user.additional && user.additional.profile && user.additional.profile.family_name) 
                        this.user_profile.lastName = user.additional.profile.family_name;
                }
                this.user_profile.fullName = this.user_profile.displayName;
                
                if (user.photoURL) this.user_profile.image = user.photoURL;
                
                try {
                    await setDoc(doc(db, "users", user.email), this.user_profile, { merge: true });
                } catch (err) {
                    console.log(err);
                }
                console.log("Created new user from " + user);
            }
            this.selection = "user";
            if (this.post === "yes") {
                this.getJSONFromStorage("report", "reports/" +  user.email + ".json");  
            }
            this.requestUpdate();
        } else {
            this.requestUpdate();
        }  
    }
    
    togglePrintable(e) {
        this.printable = !this.printable;
    }

    render() {
        return html`
        
            ${this.space_domain && this.message_domain && this.relationship_domain && this.time_domain ? html`
                ${this.printable ? html`
                    <intrivity-printable .user_profile=${this.user_profile} .report=${this.report} .message_domain=${this.message_domain} .space_domain=${this.space_domain} .relationship_domain=${this.relationship_domain} .time_domain=${this.time_domain}></intrivity-printable>
                `: html`

                    <div style="display: grid; grid-template-columns: ${window.innerWidth < 600 ? '1fr': '1fr 4fr'};">

                        <intrivity-header .printable=${this.printable} .user_profile=${this.user_profile} style="grid-column-start: 1; grid-column-end: ${window.innerWidth < 600 ? '2': '3'};"></intrivity-header>

                        <intrivity-nav .report=${this.report} .group_reports=${this.group_reports} .user_profile=${this.user_profile} .selection=${this.selection} .horizontal=${window.innerWidth < 600}></intrivity-nav>

                        <div>
        
                            ${this.report && this.group_reports ? html`
                                <intrivity-name-banner .group_report=${this.group_report} .group_reports=${this.group_reports} .user_profile=${this.user_profile} size="0.9" m="${getScore(this.report.m.average)}" r="${getScore(this.report.r.average)}" t="${getScore(this.report.t.average)}" s="${getScore(this.report.s.average)}"></intrivity-name-banner>
                            `: html`
                                <intrivity-name-banner .user_profile=${this.user_profile} size="0.9"></intrivity-name-banner>
                            `}

                            ${this.selection === 'user' ? html`<intrivity-user .group_reports=${this.group_reports} .report=${this.report} .user_profile=${this.user_profile}></intrivity-user>`: html``}
                            ${this.selection === 'teams' ? html`<intrivity-teams .group_reports=${this.group_reports} .group_report=${this.group_report}></intrivity-teams>`: html``}
                            ${this.selection === 'profile' ? html`
                                <intrivity-profile .group_report=${this.group_report} .report=${this.report} .message_domain=${this.message_domain} .space_domain=${this.space_domain} .relationship_domain=${this.relationship_domain} .time_domain=${this.time_domain}></intrivity-profile>
                            `: html``}
                            ${this.selection === 'overview' ? html`<intrivity-overview></intrivity-overview>`: html``}
                            ${this.selection === 'message' ? html`
                                <intrivity-domain-details domain_prefix="m" .domain=${this.message_domain} .group_reports=${this.group_reports} .group_report=${this.group_report} .report=${this.report}></intrivity-domain-details>
                            `: html``}
                            ${this.selection === 'space' ? html`
                                <intrivity-domain-details domain_prefix="s" .domain=${this.space_domain} .group_reports=${this.group_reports} .group_report=${this.group_report} .report=${this.report}></intrivity-domain-details>
                            `: html``}                    
                            ${this.selection === 'time' ? html`
                                <intrivity-domain-details domain_prefix="t" .domain=${this.time_domain} .group_reports=${this.group_reports} .group_report=${this.group_report} .report=${this.report}></intrivity-domain-details>
                            `: html``}  
                            ${this.selection === 'relationship' ? html`
                                <intrivity-domain-details domain_prefix="r" .domain=${this.relationship_domain} .group_reports=${this.group_reports} .group_report=${this.group_report} .report=${this.report}></intrivity-domain-details>
                            `: html``}  
                            ${this.user_profile && this.selection === 'admin' ? html`
                                <intrivity-admin-config .user_profile=${this.user_profile}></intrivity-admin-config>
                                <intrivity-admin-clients .clients=${this.clients} .user_profile=${this.user_profile}></intrivity-admin-clients>
                                <intrivity-admin-users .user_profile=${this.user_profile}></intrivity-admin-users>
                            `: html``}

                        </div>

                    </div>

                `}
            `: html`<h3>Loading...</h3>`}
            
        `
    }
 
    
}
if (!customElements.get('intrivity-mrts')) customElements.define('intrivity-mrts', IntrivityMrts);



