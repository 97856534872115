import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   

export class IntrivityLikertReport extends LitElement {

    static get properties() {
        return {
            you: { type: Number },
            color: { type: String },
            colorhue: { type: Number },
            colorsat: { type: Number },
            colorlight: { type: Number },
            groups: { type: Array },
            max: { type: Number },
            min: { type: Number },
            left_text: { type: String },
            right_text: { type: String },
            size_max: { type: Number }
        };
    }

    constructor() {
        super();
        this.max = 15;
        this.min = 4;
        this.size_max = 5;
        this.generateSVG = this.generateSVG.bind(this);
        this.getWeight = this.getWeight.bind(this);
        this.getWeightSz = this.getWeightSz.bind(this);
        this.groups = [];
    }

    connectedCallback() {
        super.connectedCallback();
    }

    disconnectedCallback() {
        super.disconnectedCallback();
    }
    
    // Size of each group marker should be scaled based on max size of score
    // the size of the score and the max pixel size for the component.
    getWeight(g) {
        let x =  Math.trunc((( this.max ) * g.size) / this.size_max) + this.min;
        return x;
    }
    getWeightSz(sz) {
            let x =  Math.trunc((( this.max ) * sz) / this.size_max) + this.min;
            return x;
    }

    generateSVG() {
        if (this.size_max < 5) this.size_max = 5;
        let svg = '<svg style="width: 100%;" xmlns="http://www.w3.org/2000/svg" version="1.1">';
        svg += '<rect x="5%" y="50%" stroke="white" stroke-width="3" width="18%" height="24px" transform="translate(0,-12)" fill="hsl(' + this.colorhue + ',' + this.colorsat + '%,' + this.colorlight + '%)"/>';
        svg += '<rect x="23%" y="50%" stroke="white" stroke-width="3" width="18%" height="24px" transform="translate(0,-12)" fill="hsl(' + this.colorhue + ',' + this.colorsat + '%,' + this.colorlight + '%)"/>';
        svg += '<rect x="41%" y="50%" stroke="white" stroke-width="3" width="18%" height="24px" transform="translate(0,-12)" fill="hsl(' + this.colorhue + ',' + this.colorsat + '%,' + this.colorlight + '%)"/>';
        svg += '<rect x="59%" y="50%" stroke="white" stroke-width="3" width="18%" height="24px" transform="translate(0,-12)" fill="hsl(' + this.colorhue + ',' + this.colorsat + '%,' + this.colorlight + '%)"/>';
        svg += '<rect x="77%" y="50%" stroke="white" stroke-width="3" width="18%" height="24px" transform="translate(0,-12)" fill="hsl(' + this.colorhue + ',' + this.colorsat + '%,' + this.colorlight + '%)"/>';
        if (this.left_text) {
            svg += '<text x="4%" y="25" dominant-baseline="start" text-anchor="start" fill="hsl(' + this.colorhue + ',' + this.colorsat + '%,' + this.colorlight + '%)"/>';
            svg += this.left_text;
            svg += '</text>';
        } 
        if (this.right_text) {
            svg += '<text x="96%" y="25" dominant-baseline="end" text-anchor="end" fill="hsl(' + this.colorhue + ',' + this.colorsat + '%,' + this.colorlight + '%)"/>';
            svg += this.right_text;
            svg += '</text>';
        }
        
        // Add in groups
        this.groups.map((group)=>{ 
                svg += '<circle onclick="alert(\"stuff\");" cx="' + ((group.score*18)+5) + '%" cy="50%" r="' + this.getWeight(group) + '" fill-opacity="30%" />'; 
            ``});
        // Add in you
        if (this.you) {
            svg += '<rect x="' + ((this.you*18)+5) + '%" y="25%" width="4px" height="50%" transform="translate(-2,0)" fill="black"/>';
            svg += '<text x="' + ((this.you*18)+5) + '%" y="25" transform="translate(-2,-15)" dominant-baseline="middle" text-anchor="middle" fill="black">';
            svg += 'You<tspan x="' + ((this.you*18)+5) + '%" y="45">' + this.you + '</tspan></text>';
        }
        svg += '<svg viewBox="0 0 80 60" x="80%" y="55" width="80">';
        // Add in groups
        svg += '<circle cx="20%" cy="50%" r="' + this.getWeightSz(1) + '" fill-opacity="30%" />';
        svg += '<text font-size="small" x="20%" y="50%" transform="translate(-1,2)" dominant-baseline="middle" text-anchor="middle" fill="black">1</text>';           
        svg += '<circle cx="39%" cy="50%" r="' + this.getWeightSz(this.size_max/2) + '" fill-opacity="30%" />';
        svg += '<circle cx="65%" cy="50%" r="' + this.getWeightSz(this.size_max) + '" fill-opacity="30%" />';
        svg += '<text font-size="small" x="65%" y="50%" transform="translate(5,2)" dominant-baseline="middle" text-anchor="middle" fill="black">' + this.size_max + '</text>';
        svg += '</svg>';
        svg += '</svg>';
        
        return svg;
    }
    
    render() {
        return html`
            ${ unsafeHTML(this.generateSVG()) }        
        `
    } 
 
}
if (!customElements.get('intrivity-likert-report')) customElements.define('intrivity-likert-report', IntrivityLikertReport);
