import { html, css, LitElement } from 'lit-element';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { clone, cloneDeep } from 'lodash-es';
import { library, dom } from "@fortawesome/fontawesome-svg-core";   
 
import './badge.js';

export class IntrivityTeamsSelector extends LitElement {

    static get styles() {
        return css`
            .teams {
                padding: 5px;
                width: 100%;
                color: white;
                background-color: #71797E;
            }
        `;
    }
    
    static get properties() {
        return {
            group_reports: { type: Array },
            expanded: { type: Boolean }
        };
    }

    constructor() {
        super(); 
    }

    render() {
        return html`
        
            <div style="margin-bottom: 5px; width: 100%; color: bl;ack; font-size: 1.5em; padding: 10px; padding-top: 15px; padding-left: 30px; background-color: gray;">
                MY TEAMS  
            </div>
        `
    } 
 
}
if (!customElements.get('intrivity-teams-selector')) customElements.define('intrivity-teams-selector', IntrivityTeamsSelector);
